<template>
  <div>
    <div class="headline font-weight-bold ml-2 mt-5 mb-10">
      Ainda tem dúvidas? Dê uma olhada no que já nos perguntaram
    </div>
    <v-expansion-panels multiple class="rounded-card">
      <v-expansion-panel
        active-class="rounded-card"
        v-for="(duvida, index) in duvidas"
        :key="index"
      >
        <v-expansion-panel-header disable-icon-rotate>
        <span class="title font-weight-bold text--primary--color pt-2 pb-2">
          {{ duvida.duvida }}
        </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="large-text mb-4">
            {{ duvida.resposta }}
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="text-right">
      <router-link to="/faq">ver mais</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      duvidas: [
        {
          duvida: 'Como funciona a entrega dos produtos comprados pelo marketplace da Proxpect?',
          resposta: 'A entrega dos produtos comprados dentro do marketplace da Proxpect podem se dar de 3 (três) maneiras: frete grátis (CIF), frete com taxa (CIF) e retirar na loja (FOB).\n\nTanto o frete grátis (CIF) quanto o frete com taxa (CIF) significam que o fornecedor irá levar o produto para sua empresa. Temos fretes grátis para diversas cidades e regiões e muitas empresas oferecem frete grátis a partir de um determinado valor de compra.\n\nPara alguns produtos, é necessário fazer a retirada no local indicado pelo fornecedor. Caso tenha urgência para o produto, você também pode optar por retirar na loja.',
        },
        {
          duvida: 'Como funciona o prazo de pagamento dentro do marketplace da Proxpect?',
          resposta: 'O prazo de pagamento é estabelecido e cadastrado conforme cada fornecedor. Os prazos podem mudar de fornecedor para fornecedor nos seguintes casos:\n\n- Políticas internas de preço e prazo de pagamento;\n- Valor e volume das compras;\n- Análise de crédito de empresas compradoras.',
        },
        {
          duvida: 'Existe custo para acessar o marketplace da Proxpect?',
          resposta: 'Você não precisa pagar nada. Basta fazer um usuário, usufruir de mais de 40 mil ofertas ativas e comprar de maneira rápida, eficiente e com preços competitivos.\n\nNão existem metas para volume de compras por empresa ou comprador e nem limite de usuários compradores por empresa.',
        },
        {
          duvida: 'Consigo ver quem é o fornecedor que está vendendo os produtos no marketplace da Proxpect?',
          resposta: 'Você consegue ter acesso a essa informação após fazer login com seu usuário do marketplace.',
        },
        {
          duvida: 'Consigo me comunicar com o fornecedor ou comprador no marketplace da Proxpect?',
          resposta: 'Você consegue se comunicar com o fornecedor. Para isto existem dois pontos de contato: as dúvidas e perguntas nas próprias ofertas de produtos e  o chat dentro da compra realizada.\n\n',
        },
      ],
    };
  },
};
</script>
